@import url(https://fonts.googleapis.com/css?family=Oswald:700|Quattrocento);
/* Home */
.header {
	position: relative;
	text-align: center;
	align-items: center;
	min-height: 100vh;
	justify-content: center;
	margin: 0;
	color: #ececec;
	font-family: Quattrocento, serif;
	line-height: 1.8925;
	background: #000;
	padding: 20px 0;
}
/* .header:before,
.header:after {
	content: "";
	position: absolute;
}
.header:before {
	top: 2%;
	right: 2%;
	bottom: 2%;
	left: 2%;
	border: 1px solid #efefef;
	z-index: 2;
} */
.header > div {
	z-index: 3;
}

.waves {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 15vh;
	min-height: 100px;
	max-height: 150px;
}

/* Animation */
.parallax > use {
	animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
	animation-delay: -2s;
	animation-duration: 7s;
}
.parallax > use:nth-child(2) {
	animation-delay: -3s;
	animation-duration: 10s;
}
.parallax > use:nth-child(3) {
	animation-delay: -4s;
	animation-duration: 13s;
}
.parallax > use:nth-child(4) {
	animation-delay: -5s;
	animation-duration: 20s;
}
@keyframes move-forever {
	0% {
		transform: translate3d(-90px, 0, 0);
	}
	100% {
		transform: translate3d(85px, 0, 0);
	}
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
	.waves {
		height: 40px;
		min-height: 40px;
	}
	h1 {
		font-size: 24px;
	}
}
.content {
	padding: 4%;
}

.content p {
	color: #fff;
}

.content p {
	padding: 1em;
	letter-spacing: 1px;
	color: #fff;
	position: relative;
}
.content p:before,
.content p:after {
	mix-blend-mode: difference;
	content: "";
	position: absolute;
	left: calc(25% / 2);
	width: 75%;
	height: 1px;
	background: #d4a790;
}
.content p:before {
	top: 0;
}
.content p:after {
	bottom: 0;
}

.content p > button {
	margin: 10px 0;
}

.content p > a {
	display: inline;
	color: rgb(123 241 255);
}

.content p > a:hover {
	text-decoration: underline;
}

.content h1 {
	font-size: 3em;
	font-weight: bold;
	line-height: 1.125;
	letter-spacing: 2px;
	color: #fff;
	font-family: Oswald;
	margin-top: 0;
	margin-bottom: 20px;
	text-align: center;
	color: #fff;
}

.blockquote-wrapper {
	display: flex;
	padding: 0 20px;
}

/* Blockquote main style */
.blockquote {
	position: relative;
	font-family: "Barlow Condensed", sans-serif;
	align-self: center;
}

/* Blockquote header */
.blockquote h5 {
	font-family: "Abril Fatface", cursive;
	position: relative; /* for pseudos */
	color: #f50057;
	font-weight: normal;
	line-height: 1;
	margin: 0;
	border: solid 2px;
	border-radius: 20px;
	padding: 25px;
}

/* Blockquote right double quotes */
.blockquote h5:after {
	content: "";
	position: absolute;
	border: 2px solid #f50057;
	border-radius: 0 50px 0 0;
	width: 60px;
	height: 60px;
	bottom: -62.5px;
	left: 50px;
	border-bottom: none;
	border-left: none;
	z-index: 3;
}

.blockquote h5:before {
	content: "";
	position: absolute;
	width: 80px;
	border: 6px solid #000;
	bottom: -3px;
	left: 50px;
	z-index: 2;
}

/* Blockquote subheader */
.blockquote h6 {
	position: relative;
	color: #ffffff;
	font-size: 1.3rem;
	font-weight: 400;
	line-height: 1.2;
	margin: 0;
	padding-top: 15px;
	z-index: 1;
	margin-left: 150px;
	padding-left: 12px;
}

.blockquote h6:first-letter {
	margin-left: -12px;
}

/* Temp */

:root {
	--dark-color: hsl(var(--hue), 100%, 9%);
	--light-color: hsl(var(--hue), 95%, 98%);
	--base: hsl(var(--hue), 95%, 50%);
	--complimentary1: hsl(var(--hue-complimentary1), 95%, 50%);
	--complimentary2: hsl(var(--hue-complimentary2), 95%, 50%);

	--font-family: "Poppins", system-ui;

	--bg-gradient: linear-gradient(
		to bottom,
		hsl(var(--hue), 95%, 99%),
		hsl(var(--hue), 95%, 84%)
	);
}

.orb-canvas {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	z-index: 1;
}

strong {
	font-weight: 600;
}

.overlay {
	width: 100%;
	max-width: 1140px;
	max-height: 640px;
	padding: 8rem 6rem;
	display: flex;
	align-items: center;
	background: rgba(255, 255, 255, 0.375);
	box-shadow: 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.1);
	border-radius: 2rem;
	border: 1px solid rgba(255, 255, 255, 0.125);
	z-index: 2;
}

.overlay__inner {
	max-width: 36rem;
}

.overlay__title {
	font-size: 23px;
	line-height: 2.75rem;
	font-weight: 700;
	letter-spacing: -0.025em;
	margin-bottom: 2rem;
}

.text-gradient {
	background-image: linear-gradient(
		45deg,
		var(--base) 25%,
		var(--complimentary2)
	);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-background-clip: text;
	-moz-text-fill-color: transparent;
}

.overlay__description {
	font-size: 1rem;
	line-height: 1.75rem;
	margin-bottom: 1rem;
}

.overlay__btns {
	width: 100%;
	max-width: 30rem;
	display: flex;
}

.overlay__btn {
	width: 50%;
	height: 2.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.875rem;
	font-weight: 600;
	color: var(--light-color);
	background: var(--dark-color);
	border: none;
	border-radius: 0.5rem;
	transition: transform 150ms ease;
	outline-color: hsl(var(--hue), 95%, 50%);
}

.overlay__btn:hover {
	transform: scale(1.05);
	cursor: pointer;
}

.overlay__btn--transparent {
	background: transparent;
	color: var(--dark-color);
	border: 2px solid var(--dark-color);
	border-width: 2px;
	margin-right: 0.75rem;
}

.overlay__btn-emoji {
	margin-left: 0.375rem;
}

/* a {
	text-decoration: none;
	color: var(--dark-color);
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
} */
.imagesvg {
	height: 400px;
	padding: 10px;
}

/* Not too many browser support this yet but it's good to add! */
@media (prefers-contrast: high) {
	.orb-canvas {
		display: none;
	}
}

@media only screen and (max-width: 1140px) {
	.overlay {
		padding: 8rem 3rem;
		margin: auto 1rem;
	}
}

@media only screen and (max-width: 840px) {
	.overlay {
		padding: 4rem;
		height: auto;
	}

	.overlay__title {
		font-size: 1.25rem;
		line-height: 2rem;
		margin-bottom: 1.5rem;
	}

	.overlay__description {
		font-size: 0.875rem;
		line-height: 1.5rem;
		margin-bottom: 2.5rem;
	}
}

@media only screen and (max-width: 600px) {
	.overlay {
		padding: 1.5rem;
	}

	.overlay__btns {
		flex-wrap: wrap;
	}

	.overlay__btn {
		width: 100%;
		font-size: 0.75rem;
		margin-right: 0;
	}

	.overlay__btn:first-child {
		margin-bottom: 1rem;
	}
	.imagesvg {
		display: none;
	}
}


.content p > button > span > a {
	color: #fff;
}
