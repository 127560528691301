* {
  box-sizing: border-box;
  margin-left: 0;
  margin-top: 0;
  font-family: Rubik, sans-serif;
}

:root {
  --base-blue: #1338ae;
  --blue2: #cddefd5c;
  --backgroundWhite: #fcfbff;
  --backgroundWhite2: #fff;
}
.heroContainer {
  background-color: var(--backgroundWhite);
  display: flex;
  flex-direction: column;
}

/*------------------------- header css start*/
.wrapper {
  position: relative;
  text-align: center;
  background: linear-gradient(180deg, #fcfbff 0%, #4d71e4 100%);
  border-radius: 10px;
}

.headerImage img {
  height: auto; /* Maintains aspect ratio */
  background: linear-gradient(180deg, rgba(19, 56, 174, 0.04) 0%, #4d71e4 100%);
  border-radius: 10px;
  margin: 0 auto;
}

.headerText {
  color: #fff;
  font-size: 59px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  position: absolute;
  margin: 0 auto;
  top: 75%;
  left: 50%;
  transform: translate(-50%, 50%);
}

/*------------------------- header css end*/

/*------------------------- Left form css start*/

.formStatContainer {
  display: flex;
  /* flex-wrap: wrap; */
  padding: 2% 4%;
  align-items: stretch;
  justify-content: space-between;
  gap: 2%;
  color: var(--backgroundWhite);
}

.leftContainer {
  padding: 5%;
  display: flex;
  flex: 50%;
  flex-direction: column;
  /* justify-content: center;
    align-items: center; */
  border-radius: 10px;
  color: var(--backgroundWhite);
  background-color: var(--blue2);
  text-align: left;
  flex: 60%;
}

.leftContainer h1 {
  color: var(--base-blue);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Rubik, sans-serif;
  font-size: 50px;
  font-style: normal;
  text-justify: newspaper;
  font-weight: 700;
  line-height: 50px; /* 85.714% */
}

.leftContainer p {
  color: var(--base-blue);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  text-justify: newspaper;
  margin-bottom: 10%;
  line-height: 30px; /* 93.75% */
}

.messageBox {
  display: flex;
  flex-direction: column;
  float: left;
  color: transparent;
}

.messageBox h6 {
  color: var(--base-blue);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 1%;
}

.messageBox textarea {
  height: 8em;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid var(--base-blue);
  border-radius: 4px;
  background-color: var(--blue2);
  font-size: 16px;
  resize: none;
}

.formControlCustom {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid var(--base-blue);
  border-radius: 10px;
  background-color: var(--blue2);
  /* font-size: 16px; */
  resize: none;
}

.messageBox textarea::placeholder {
  padding: 1% 0 0 1%;
  color: var(--base-blue);
  font-size: 15px !important;
  font-weight: 700;
  line-height: normal; /* 120% */
}

.zipCodeFlex {
  display: flexbox !important;
  /* justify-content: start; */
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap !important;
  color: transparent;
  margin: 0 0 1% 0;
}

.zipCodeBox {
  box-sizing: border-box;
  border: 2px solid var(--base-blue) !important;
  border-radius: 4px !important;
  background-color: var(--blue2) !important;
  font-size: 16px;
  resize: none;
  margin-left: 5% !important;
}

.flexDisplay h6 {
  color: var(--base-blue);
  width: 30%;
}

/* .zipCodeFinder {
  color: #1338ae;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 17px !important;
  font-style: normal;
  font-weight: 400;
}

.zipcodeFiner_Here {
  color: #1338ae;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
} */

.flexDisplay {
  display: block;
  align-items: center;
}

.submitButton {
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  border: 2px solid rgba(205, 220, 248, 0.54);
  background: #1338ae;
  display: flex;
  height: 60px;
  padding: 20px 40px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.34px;
}

.submitButton:hover {
  background: #0c226c;
}
.noteThis {
  text-align: justify;
  font-weight: 700 !important;
  color: var(--base-blue);
  margin-top: 10%;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 20px !important;
  font-style: normal;
  line-height: 30px; /* 120% */
}

.selectedDropdown {
  display: none;
}

/*------------------------- Left form css end*/

/*------------------------- Right announcements css start*/

.rightContainer {
  display: flex;
  flex-direction: column;
  flex: 40%;
}

.announcements {
  background-color: var(--backgroundWhite2);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  /* height: 35rem; */
  overflow-x: hidden;
  max-height: min-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.01);
}

.announcements h5 {
  color: var(--base-blue);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 5% 5% 0;
}

.announcements p {
  color: var(--base-blue);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
  padding: 0 5%;
}

.announcements svg {
  padding: 2.5% 5%;
}

.instaPostFlex {
  /* background-color: var(--backgroundWhite2); */
  background-color: var(--backgroundWhite2);
  padding: 5%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.01);
}

.instaPostFlex h5 {
  color: var(--base-blue);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.instaPostFlex p {
  color: var(--base-blue);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}

.announcementContent {
  color: var(--base-blue);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 30px !important; /* 93.75% */
  min-width: 100%;
  margin-right: 10%;
}
.centered-text {
  display: flex;
  justify-content: center;
  font-size: 17px;
  font-weight: 700;
  line-height: 30px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 0 !important;
}

.boldImpInfo {
  color: #fcfbff;
}

.frameSize {
  height: 26vh;
  position: relative;
}

.imageSize {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  object-fit: contain;
}

.pieChartDiv {
  display: flex;
  justify-content: space-between;
}

.linkUnderline {
  font-feature-settings: "clig" off, "liga" off;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  padding-bottom: 2%;
  text-decoration-line: underline;
  flex-basis: calc(33.33% - 20px);
  justify-content: center;
  box-sizing: border-box;
}

.NolinkUnderline {
  text-decoration: none;
}

span.MuiTypography-root.MuiTypography-body1 {
  color: rgb(0, 0, 0) !important;
  font-weight: 900 !important;
}

label.css-j204z7-MuiFormControlLabel-root {
  justify-content: center !important;
  padding-bottom: 5% !important;
  margin: 0 !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  max-height: 400px !important;
}

.css-13aj3tc-MuiChartsSurface-root {
  padding: 0% 2% 5% !important;
}

/*------------------------- Right announcements css end*/

/*------------------------- FooterResources css Start*/
.footerResources {
  display: flex;
  border-radius: 10px;
  max-height: min-content;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
}

.footerContainer h5 {
  color: var(--base-blue);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin: 2% 0;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--blue2);
  margin-top: 2%;
  padding: 2% 10% 2%;
}

/*------------------------- FooterResources css end*/

/*------------------------- Mobile/tablet css Start*/
@media only screen and (max-width: 768px) {
  .heroContainer {
    align-items: center;
  }

  .leftContainer p {
    margin-bottom: 5%;
  }

  .formStatContainer {
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .instaPostFlex {
    justify-content: start;
    align-items: center;
    height: max-content;
  }

  .instaPostFlex h5 {
    text-align: center;
  }

  .instaPostFlex p {
    text-align: center;
  }

  .announcements {
    margin-top: 5%;
    justify-content: start;
    align-items: center;
    min-width: 100%;
  }

  .imageSize {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
    object-fit: contain;
  }

  .footerContainer {
    margin-top: 2%;
    width: 100%;
    display: flex;
    background-color: var(--blue2);
  }

  .linkUnderline {
    padding-bottom: 5%;
    line-height: 30px;
  }

  .css-6hp17o-MuiList-root-MuiMenu-list {
    max-height: 400px !important;
  }

  .css-2dkrk1-MuiStack-root {
    flex-wrap: wrap !important;
    justify-content: center !important;
  }
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 370px) {
  .headerText {
    font-size: 30px !important;
  }
  .formStatContainer {
    padding: 5% 0;
  }
  .headerImage {
    max-width: 100%;
  }
  .headerText {
    color: #fff;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    position: absolute;
    margin: 0 auto;
    top: 55%;
    left: 50%;
    transform: translate(-50%, 50%);
  }

  .linkUnderline {
    padding-bottom: 10%;
    line-height: 30px;
  }
}
